var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
        [
          _c(
            "c-search-box",
            [
              _c("template", { slot: "search" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-select", {
                      attrs: {
                        comboItems: _vm.domainItems,
                        type: "search",
                        itemText: "domainNm",
                        itemValue: "domainCd",
                        name: "domain",
                        label: "코드 도메인",
                      },
                      model: {
                        value: _vm.searchParam.srchDomainCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParam, "srchDomainCd", $$v)
                        },
                        expression: "searchParam.srchDomainCd",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "c-table",
            {
              ref: "grpTable",
              attrs: {
                title: "코드그룹",
                tableId: "grpTable",
                columnSetting: false,
                isFullScreen: false,
                usePaging: false,
                columns: _vm.grpGrid.columns,
                isExcelDown: false,
                data: _vm.grpGrid.data,
              },
              on: { rowClick: _vm.getCodeMst },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c("c-btn", {
                    attrs: { label: "검색", icon: "search" },
                    on: { btnClicked: _vm.getCodeGroup },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
        [
          _c(
            "c-table",
            {
              ref: "mstTable",
              attrs: {
                title: "코드 다국어관리",
                tableId: "mstTable",
                columnSetting: false,
                isFullScreen: false,
                usePaging: false,
                filtering: false,
                columns: _vm.mstGrid.columns,
                isExcelDown: false,
                data: _vm.mstGrid.data,
                editable: _vm.editable,
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && _vm.isSelectGrp
                        ? _c("c-btn", {
                            attrs: { label: "저장", icon: "save" },
                            on: { btnClicked: _vm.saveMst },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }