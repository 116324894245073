<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-search-box>
          <template slot="search">
            <div class="col-12">
              <c-select
                :comboItems="domainItems"
                type="search"
                itemText="domainNm"
                itemValue="domainCd"
                name="domain"
                label="코드 도메인"
                v-model="searchParam.srchDomainCd"
              ></c-select>
            </div>
          </template>
        </c-search-box>
        <c-table
          ref="grpTable"
          title="코드그룹"
          tableId="grpTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :columns="grpGrid.columns"
          :isExcelDown="false"
          :data="grpGrid.data"
          @rowClick="getCodeMst"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <c-btn label="검색" icon="search"  @btnClicked="getCodeGroup"/>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="mstTable"
          title="코드 다국어관리"
          tableId="mstTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :columns="mstGrid.columns"
          :isExcelDown="false"
          :data="mstGrid.data"
          :editable="editable"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="저장" icon="save" @btnClicked="saveMst" v-if="editable && isSelectGrp" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'code-master',
  data() {
    return {
      editable: true,
      prompt: false,
      searchParam: {
        srchDomainCd: null,
      },
      hiddenMstCd: '',
      isSelectGrp: false,
      selectedGroupCd: '',
      domainItems: [],
      domainlistUrl: '',
      grouplistUrl: '',
      mstSaveUrl: '',
      mstDeleteUrl: '',
      grpGrid: {
        columns: [
          {
            name: 'codeGrpCd',
            field: 'codeGrpCd',
            label: '그룹 코드',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'codeGrpNm',
            field: 'codeGrpNm',
            label: '그룹명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      mstGrid: {
        columns: [
          {
            name: 'code',
            field: 'code',
            label: '코드',
            align: 'center',
            style: 'width:140px',
            sortable: false,
          },
          {
            name: 'codeNameKr',
            field: 'codeNameKr',
            label: '코드명 한글',
            align: 'left',
            type: 'text',
            required: true,
            sortable: false,
            style: 'width:18%',
          },
          {
            name: 'codeNameEn',
            field: 'codeNameEn',
            label: '코드명 영문',
            align: 'left',
            type: 'text',
            sortable: false,
            style: 'width:18%',
          },
          {
            name: 'codeNameJa',
            field: 'codeNameJa',
            label: '코드명 일문',
            align: 'left',
            type: 'text',
            sortable: false,
            style: 'width:18%',
          },
          {
            name: 'codeNameZhCn',
            field: 'codeNameZhCn',
            label: '코드명 중국어간체',
            align: 'left',
            type: 'text',
            sortable: false,
            style: 'width:18%',
          },
          {
            name: 'codeNameZhTw',
            field: 'codeNameZhTw',
            label: '코드명 중국어번체',
            align: 'left',
            type: 'text',
            sortable: false,
            style: 'width:18%',
          },
        ],
        data: [],
      },
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = (this.$store.getters.user.userId === 'U000000001' ? this.$route.meta.editable : false);
      this.domainlistUrl = selectConfig.sys.code.domain.list.url;
      this.grouplistUrl = selectConfig.sys.code.group.list.url;
      this.mstlistUrl = selectConfig.sys.code.mst.langlist.url;
      this.mstSaveUrl = transactionConfig.sys.code.lang.save.url;
      this.getCodeDomain();
      this.getCodeGroup();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    getCodeDomain() {
      this.$http.url = this.domainlistUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.domainItems = _result.data;
      },);
    },
    getCodeGroup() {
      this.rowRemoveSelect();
      this.isSelectGrp = false;
      this.mstGrid.data = [];
      this.$http.url = this.$format(this.grouplistUrl, this.searchParam.srchDomainCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grpGrid.data = _result.data;
      },
      () => {
      });
    },
    getCodeMst(row) {
      this.isSelectGrp = true;
      this.selectedGroupCd = row.codeGrpCd;
      this.$http.url = this.$format(this.mstlistUrl, this.selectedGroupCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.mstGrid.data = _result.data;
      },
      () => {
      });
    },
    saveMst() {
      if (this.$comm.validTable(this.mstGrid.columns, this.mstGrid.data)) {
        let saveData = this.mstGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.mstSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('ALERT', {
                  title: '안내', 
                  message: '저장되었습니다.', 
                  type: 'success', // success / info / warning / error
                });
                this.getCodeMst({codeGrpCd: saveData[0].codeGrpCd});
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
  }
};
</script>
